<template>
  <v-app>
    <!--layout-app-app-bar /-->
    <layout-pos-drawer :key="keyRender" />

    <layout-pos-view />
    <v-alert
      v-if="$store.getters.orderReceived"
      icon="mdi-receipt"
      class="white--text alert_pending_orders"
      prominent
      dense
      style="position: fixed; top: 1em;  z-index: 1000"
      :style="stylesAlert"
    >
      <v-row
        align="center"
        class="rounded-lg pa-lg-0 pa-2 text-center text-lg-left"
      >
        <v-col cols="12" lg="9">
          <span class="subtitle-1 font-weight-bold"
            >Nueva orden pendiente por recibir</span 
          >
        </v-col>
        <v-col cols="12" lg="3" class="text-lg-right pl-0 ma-sm-0">
          <v-btn
            class="ma-2 ma-sm-1 grey--text text--darken-1"
            block
            @click="openModalOrdersReceived"
            color="white"
            ><v-icon left>mdi-eye</v-icon>Ver Orden</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
    <CartPendingOrdersReceived
      :dialog="showModalOrdersReceived"
      @cancel="showModalOrdersReceived = false"
      @close="showModalOrdersReceived = false"
      @order:acepted="orderAcepted"
      ref="modalOrdersReceived"
    >
    </CartPendingOrdersReceived>
  </v-app>
</template>



<script>
import {
  //isStoreActive,
  existsStore,
  createStore,
  ping,
  setTokenFcm,
} from "@/plugins/db-firestore";
import CartPendingOrdersReceived from "@/components/modules/pos/components/CartPendingOrdersReceived.vue";

export default {
  name: "PosIndex",
  components: { CartPendingOrdersReceived },
  data: () => ({
    expandOnHover: false,
    keyRender: 0,
    bgColor: "orange lighten-4",
    ws: null,
    isFlashing: false,
    showModalOrdersReceived: false
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    stylesAlert() {
      return {
        backgroundColor: this.bgColor,
        left: this.$store.getters.mobile ? "5%" : "25%",
        right: this.$store.getters.mobile ? "5%" : "25%",
      }
    }
  },
  watch: {
    //installation
    async user(val) {
      if (val) {
        let installation = this.$store.getters.installation;
        if(this.$store.getters.getGeneralValue('gen_sales_manage_screens') || 
        this.$store.getters.getGeneralValue('gen_sales_manage_waiters') ||
        this.$store.getters.getGeneralValue('gen_sales_manage_delivery')){
            let st = await existsStore(installation.subsidiary_id);
            console.log('st',st);
            //gen_sales_manage_screens, gen_sales_manage_waiters, gen_sales_manage_delivery
            if (!st) {
              createStore(installation.subsidiary_id);
            } else {
              ping(installation.subsidiary_id);
              setTokenFcm(this.$http);
            }
      }
        this.loadTurn();
      }
    },
    "$store.getters.orderReceived": function (val) {
      console.log("orderReceived......1");
      if (val) {
        if (!this.flashInterval) {
          this.activateFlash();
        }
      } else {
        if (this.flashInterval) {
          clearInterval(this.flashInterval);
          this.flashInterval = null;
          this.isFlashing = false;
        }
      }
    },
  },
  mounted() {
    this.activateFlash();

    setTimeout(() => {
      //console.log('1111111111111111111111111111111111')
      if (!this.user) {
        this.$router.push("/login");
      } else {
        this.getTotalOrdersToReceive();
      }
    }, 1000);
   /* let count = 1;
    setInterval(() => {
      count++;
      let v = count % 2 ? true : false;
      console.log("borrar...");
      this.$store.commit("setOrderReceived", v);
    }, 2000);*/
  },
  methods: {
    orderAcepted() {
      this.$store.commit("setOrderReceived", true);
    },
    async getTotalOrdersToReceive() {
      let installation = this.$store.getters.installation;
      this.$http
        .get("api/v1/orders-to-receive-total", {
          //from_seller: true,
          //order_status: "pending",
          subsidiary: [installation.subsidiary_id],
        })
        .then((response) => {
          if (response.total > 0) {
            this.$store.commit("setOrderReceived", true);
          }
        });
    },

    loadTurn() {
      const installation = this.$store.getters.installation;
      // console.log('installation',installation);
      if (!installation) {
        return;
      }
      const cash_register = installation.cash_id;
      this.$http
        .get(
          "api/v1/pos/current-turn/" +
            this.$store.getters.installation.subsidiary_id,
          { cash_register: cash_register }
        )
        .then((response) => {
          if (response.success) {
            //console.log(response)
            this.$store.commit("setTurn", response.turn);

            // console.log("turn", response.turn);
          }
        })
        .catch((e) => {});
    },
    openModalOrdersReceived() {
      this.showModalOrdersReceived = true;
      this.$refs.modalOrdersReceived.getPendingOrders();
    },
    activateFlash() {
       if (this.$store.getters.orderReceived) {
         this.flashInterval = setInterval(this.toggleFlash, 500);
       }
    },
    toggleFlash() {
      // Cambia el color del fondo entre dos opciones
      this.bgColor = this.isFlashing ? "#FFAB00" : "#000";
      // Invierte el estado de "isFlashing"
      this.isFlashing = !this.isFlashing;
    },
  },
};
</script>
<style>
.v-main {
  background: #fafafa !important;
}

.v-main__wrap {
  padding-top: 21px !important;
}

@media only screen and (max-width: 981px) {
  .v-main__wrap {
    padding-top: 90px !important;
  }
}

.v-item--active {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}

.v-btn-toggle > .v-btn.v-btn {
  opacity: 1;
}
.alert_pending_orders .v-alert__icon {
  color: #FFF !important;
}
</style>